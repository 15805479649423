body {
  margin: 0;
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  overflow-x: hidden;
  padding: 0!important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.MuiFormHelperText-root {
  margin-left: 0 !important;
}

html {
  width: 100%;
  overflow-x: hidden;
}

#root {
  width: 100%;
  overflow-x: hidden;
}

#g-recaptcha {
  width: 100%;
}

/* Dialog width */
.MuiDialog-paperWidthXl {
  max-width: 92% !important;
  width: 92% !important;
}

.print-data-container {
  display: flex;
  margin-top: 1rem;
  border: 1px solid gray;
  border-radius: 10px;
}

.receta-data-container {
  display: flex;
  margin-top: 1rem;
  border: 1px solid gray;
  border-radius: 10px;
  padding: 16px;
  flex-direction: column;
}

.print-data-left {
  display: flex;
  align-items: center;
  width: 50%;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1.5%;
  padding-right: 1.5%;
  border-right: 1px solid gray;
}

.print-data-right {
  display: flex;
  align-items: center;
  width: 50%;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1.5%;
  padding-right: 1.5%;
}

.table-full-width{
  width: 100%;
}

.qr-reader-container video{
  height: auto !important;
  margin-top: 2rem;
  width: 100%;
}

.general-p {
  font-size: 10px;
  margin-bottom: 6px;
  margin-top: 0;
  text-transform: uppercase;
}

.th {
  font-size: 10px;
}

/*Ipad to phone css*/
@media only screen and (max-width: 960px) {
  /* Dialog width */
  .MuiDialog-paperWidthXl {
    max-width: 95% !important;
    width: 95% !important;
    margin: 5% !important;
  }
}
